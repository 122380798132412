<template>
  <div>
    <div>
      <div>{{ $t("Qual o valor que você deseja sacar?") }}</div>
      <b-form-input type="number" placeholder="Digite o valor desejado" v-model="requestValue" :state="requestValue == 0
          ? null
          : requestValue <= returnUser.balance && requestValue >= 40
        " />
      <span v-if="requestValue <= returnUser.balance &&
        requestValue >= 40 &&
        requestValue > 0
        " class="text-success">Este valor está disponível para saque</span>
      <span v-if="requestValue <= returnUser.balance &&
        requestValue < 40 &&
        requestValue > 0
        " class="text-danger">O valor mínimo para saque é de R$ 40,00</span>
      <span v-if="requestValue > returnUser.balance && requestValue > 0" class="text-danger">Você não tem saldo suficiente
        para um saque deste valor</span>
      <span v-if="requestValue < 0" class="text-danger">Valor inválido para saque</span>
    </div>
    <div class="mt-1" v-if="requestValue <= returnUser.balance && requestValue >= 40">
      <div>
        {{ $t("Dias disponíveis para saque") }}:
        <span class="text-warning">de segunda a sexta-feira</span>
      </div>
      <div>
        {{ $t("Taxa de saque") }}:
        <span class="text-warning">2%</span>
      </div>
      <div>
        {{ $t("Valor à receber com desconto") }}:
        <span class="text-info">{{ moneyFormat(requestValue * 0.98) }}</span>
      </div>
      <div class="mt-1">
        Como você deseja receber?
        <div class="d-flex">
          <b-button variant="outline-primary" block class="mt-1 mr-2" @click="payMethod = 'crypto'">Criptomoeda</b-button>
          <b-button variant="outline-info" block class="mt-1 mr-2" @click="payMethod = 'pix'">PIX</b-button>
        </div>
      </div>
      <div v-if="payMethod == 'crypto'">
        <div class="mt-1">
          <div>{{ $t("Selecione em qual criptomoeda deseja receber") }}:</div>
          <b-form-radio-group v-model="cryptoSelected" :options="cryptoOptions" class="my-1" name="radio-inline" />
        </div>
        <div>
          <div>
            {{ $t("O valor solicitado será enviado para a seguinte carteira") }}
            {{ cryptoSelected }}:
          </div>
          <div v-if="cryptoSelected == 'USDT.BEP20'">
            <div class="text-info mt-1" v-if="returnUser.addressUSDT">
              {{ returnUser.addressUSDT }}
            </div>
            <div v-if="!returnUser.addressUSDT" class="text-warning mt-1">
              {{ $t("Você ainda não cadastrou seu endereço USDT") }}
              <b-button variant="warning" size="sm" @click="() => $router.push('/account-setting')">
                {{ $t("Cadastrar minha carteira USDT") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="payMethod == 'pix'">
        <div class="mt-1">
          <!-- <div>
            {{ $t("Valor à receber em BRL") }}:
            <span class="text-info">{{
              withdrawValueInBRL.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</span>
          </div> -->
          <div class="mt-1">
            {{ $t("O valor será enviado para o PIX") }}:
            <span class="text-info mt-1" v-if="returnUser.pixAccount">
              {{ returnUser.pixAccount }}
            </span>
          </div>
          <div>
            <div v-if="!returnUser.pixAccount" class="text-warning mt-1">
              {{ $t("Você ainda não cadastrou sua conta PIX") }}
              <b-button variant="warning" size="sm" @click="() => $router.push('/account-setting')">
                {{ $t("Cadastrar minha conta PIX") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert" @changeVariableAlert="(val) => (showAlert = val)"
      class="mb-1" />
    <div class="text-right">
      <b-button variant="primary" :disabled="!(requestValue <= returnUser.balance && requestValue >= 10) ||
        (cryptoSelected == 'BTC' && !returnUser.addressBTC) ||
        loading ||
        getDiaDaSemana() == 'sábado' ||
        getDiaDaSemana() == 'domingo' ||
        payMethod == ''
        " @click="withdrawRequest">
        <div v-if="!loading">
          {{ $t("Solicitar saque") }}
        </div>
        <div v-else>
          <b-spinner small type="grow"></b-spinner>
          {{ $t("Aguarde...") }}
        </div>
      </b-button>
      <b-button class="ml-1" @click="() => $emit('closeModal')">{{
        $t("Sair")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BFormRadioGroup, BFormGroup } from "bootstrap-vue";
import { debounce } from "debounce";

export default {
  components: {
    BFormRadioGroup,
    BFormGroup,
  },
  data() {
    return {
      requestValue: 0,
      cryptoSelected: "USDT.BEP20",
      cryptoOptions: [{ text: "USDT", value: "USDT.BEP20" }],
      loading: false,
      showAlert: false,
      colorAlert: "warning",
      textAlert: "",
      payMethod: "",
      withdrawValueInBRL: 0,
    };
  },
  watch: {
    requestValue: debounce(function (newVal) {
      this.$store
        .dispatch("convertUSDtoURL", newVal)
        .then((resp) => {
          this.withdrawValueInBRL =
            this.requestValue * 0.97 * parseFloat(resp.ask);
        })
        .catch((err) => {
          // console.log(err);
        });
    }, 1000),
  },
  computed: mapGetters(["returnUser"]),
  methods: {
    getDiaDaSemana() {
      const dataAtual = new Date();
      const opcoes = { weekday: "long" };
      const diaDaSemana = dataAtual.toLocaleString("pt-BR", opcoes);
      return diaDaSemana;
    },
    withdrawRequest() {
      this.loading = true;
      let data = {};
      if (this.payMethod == "crypto") {
        data = {
          value: this.requestValue,
          address:
            this.cryptoSelected == "USDT.BEP20" && this.returnUser.addressUSDT,
          crypto: this.cryptoSelected,
          method: this.payMethod,
        };
      }
      if (this.payMethod == "pix") {
        data = {
          value: this.requestValue,
          valueBRL: this.withdrawValueInBRL,
          pixAccount: this.returnUser.pixAccount,
          method: this.payMethod,
        };
      }

      this.$store
        .dispatch("withdrawRequest", data)
        .then((response) => {
          this.$store.dispatch("changeBalance", -this.requestValue);
          this.$emit("renderUsersList");
          this.loading = false;
          this.requestValue = 0;
          this.showAlert = true;
          this.colorAlert = "success";
          this.textAlert = this.$i18n.t("Saque solicitado com sucesso!");
          setTimeout(() => {
            this.$emit("closeModal");
          }, 2000);
        })
        .catch((error) => {
          this.loading = false;
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = this.$i18n.t(error.message.extra.data.error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
